<template>
  <div class="absolute top-0 right-0 z-40 w-full h-[70px] mt-[49px] mr-[87px] flex items-center justify-end text-6xl uppercase font-highlights text-white">
    <span class="mr-[30px] font-bold drop-shadow-md">{{ program ? program.title : '' }}</span>
    <span class="mr-[40px] drop-shadow-md">{{ time }}</span>
    <Logo class="h-full" />
  </div>
</template>

<script>
import stationId from '../stationId'
import QWebsocket from '../vendor/q-websocket'
import Logo from './Logo.vue'

export default {
  components: { Logo },
  data() {
    return {
      program: null,
      now: new Date(),
    }
  },
  mounted() {
    new QWebsocket(stationId).programHandler(program => {
      console.log(program)
      this.program = program
    })
    setInterval(() => {
      this.now = new Date()
    }, 1000)
  },
  methods: {
    twoDigits(value) {
      if (value.toString().length <= 1) {
        return '0' + value.toString()
      }
      return value.toString()
    },
  },
  computed: {
    time() {
      return this.twoDigits(this.now.getHours()) + ':' + this.twoDigits(this.now.getMinutes())
    },
  },
}
</script>
