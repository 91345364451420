<template>
  <div class="absolute w-full h-full flex items-end justify-center z-20">
    <button v-if="inDevelopment" class="absolute bg-white" @click="paused = !paused">Pause</button>
    <div class="w-bar mb-24 flex items-end">

      <div class="flex-1 transition-all duration-150 ease-in-out" :class="visible && image ? 'max-w-[1047px] w-[1047px]' : 'max-w-[1594px]'">
        <Label :item="item" :visible="visible" :title-transition="titleTransition" />

        <div>
          <div
            v-show="visible"
            ref="itemContainer"
            :class="['bg-' + item.color()]"
            class="relative w-full overflow-hidden rounded-[4rem] h-0 transition-[height] ease-in-out duration-300"
          >
            <transition name="slide">
              <div v-if="transition" class="absolute w-[180%] h-full">
                <div :class="'bg-' + transitionColor" class="w-full h-full -skew-x-15" />
              </div>
            </transition>

            <div ref="item" class="py-8 px-8 text-q-gray">
              <NowPlaying v-show="item.type === 'now-playing'" @track="activate" :content="item.content" />
              <AppMessage v-show="item.type === 'app-message'" :content="item.content" />
              <Headline v-show="item.type === 'headline'" :content="item.content" />
              <LongText v-show="item.type === 'long-text'" :content="item.content" />
              <ScoreUpdate v-show="item.type === 'score-update'" :content="item.content" />
              <CountUp v-show="item.type === 'count-up'" :content="item.content" />
            </div>
          </div>
        </div>
      </div>

      <div class="overflow-hidden ml-4 transition-all duration-150 ease-in-out" :class="visible && image ? 'max-w-[531px] w-[531px]' : 'max-w-[0px]'">
        <div class="rounded-[4rem] bg-black overflow-hidden w-[531px] aspect-video">
          <DynamicGraphicsImage :banner-image="image" />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import BackendActions from './../BackendActions'
import BackendScheduler from './../BackendScheduler'
import NoScheduleEntry from './../NoScheduleEntry'

import DynamicGraphicsImage from './DynamicGraphicsImage.vue'

import NowPlaying from './items/NowPlaying.vue'
import AppMessage from './items/AppMessage.vue'
import Headline from './items/Headline.vue'
import LongText from './items/LongText.vue'
import ScoreUpdate from './items/ScoreUpdate.vue'
import CountUp from './items/CountUp.vue'
import Label from './Label.vue'

const dynamicGraphicsTime = 300
const titleTransitionTime = 500
const transitionTime = 1000

export default {
  components: { DynamicGraphicsImage, NowPlaying, AppMessage, Headline, LongText, ScoreUpdate, CountUp, Label },
  data() {
    return {
      inDevelopment: process.env.NODE_ENV === 'development',
      item: NoScheduleEntry,
      active: false,
      paused: false,
      visible: false,
      transition: false,
      transitionColor: 'black',
      titleTransition: false,
      image: false,
      scheduler: new BackendScheduler(),
      ignoreResize: false,
      timeout: null,
    }
  },
  mounted() {
    BackendActions.instance().listenForInteractiveGraphicsShow(this.activate)
    BackendActions.instance().listenForInteractiveGraphicsHide(this.deactivate)

    const resizeObserver = new ResizeObserver(() => {
      if (this.ignoreResize) {
        return
      }
      this.$refs.itemContainer.style.height = this.$refs.item.clientHeight + "px"
    })
    resizeObserver.observe(this.$refs.item)
  },
  watch: {
    paused() {
      if (!this.paused) {
        this.setNextItem()
      }
    },
  },
  methods: {
    setNextItem() {
      if (this.paused) {
        return
      }

      // Hide item after the previous one has completed when not active anymore
      if (!this.active) {
        this.hide()
        return
      }

      const newItem = this.scheduler.nextItem()

      // If no item available, deactivate and hide
      if (newItem === undefined) {
        this.deactivate()
        this.hide()
        return
      }

      // Show new item with animations
      this.showNewItem(newItem)

      // Set next item after this one has finished
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => this.setNextItem(), newItem.duration)
    },
    showNewItem(newItem) {
      // Start animation - hide title
      this.titleTransition = true

      const noPreviousItem = !this.visible
      this.visible = true

      const titleTransitionTimeOffset = noPreviousItem ? 0 : titleTransitionTime

      // Start main animation after title is hidden
      if (!noPreviousItem) {
        setTimeout(() => { 
          this.transitionColor = newItem.color()
          this.transition = true
        }, titleTransitionTimeOffset)
      }

      // Show/hide image
      if (!newItem.content.image_url) {
        setTimeout(() => {
          this.ignoreResize = true
          this.image = newItem.content.image_url
        }, titleTransitionTimeOffset + 200)
      }
      if (newItem.content.image_url) {
        setTimeout(() => {
          this.ignoreResize = true
          this.image = newItem.content.image_url
        }, titleTransitionTimeOffset + 400)
      }

      // Perform after animation is halfway
      setTimeout(() => {
        this.ignoreResize = false
        this.item = newItem
      }, titleTransitionTimeOffset + transitionTime / 2)

      // Reset animation
      setTimeout(() => { this.titleTransition = false }, titleTransitionTimeOffset + transitionTime / 2)
      setTimeout(() => { this.transition = false }, titleTransitionTimeOffset + transitionTime)
    },
    hide() {
      this.titleTransition = true
      setTimeout(() => {
        this.$refs.itemContainer.style.height = '0px'
        this.$refs.itemContainer.style.width = this.$refs.item.clientWidth + "px";
        this.image = null
      }, 300)
      setTimeout(() => { 
        this.visible = false
        this.item = NoScheduleEntry
        this.$refs.itemContainer.style.width = '100%'
      }, dynamicGraphicsTime + 500)
    },
    activate() {
      if (!this.active) {
        this.active = true
        this.scheduler.updateItems().then(() => this.setNextItem())
      }
    },
    deactivate(options = {}) {
      this.active = false
      this.scheduler.clear()
      if (options.force) {
        this.hide()
      }
    }
  }
}
</script>

<style scoped>
.w-bar {
  width: 1594px;
}

.slide-leave-active,
.slide-enter-active {
  transition: all 1s linear;
  transform: translate(100%, 0);
}
.slide-enter {
  transform: translate(-100%, 0);
}
.slide-leave-to {
}

.popup-leave-active,
.popup-enter-active {
  transition: all .5s ease-in-out;
  max-width: 1920px;
}
.popup-enter, .popup-leave-to {
  max-width: 0;
}
</style>
