<template>
  <div class="h-full flex items-center font-highlights text-6xl com:text-9xl com:text-white uppercase font-bold leading-none">
    <div>
      <div class="max-3-lines leading-extra-tight">
        {{ content.message }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['content']
}
</script>
