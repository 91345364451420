export default class Resizer {
  constructor(className = 'resizable') {
    this.className = className
  }

  resize() {
    Array.from(document.getElementsByClassName(this.className)).forEach(div => {
      let divWidth = div.clientWidth;
      let contentWidth = div.firstChild.scrollWidth;

      if (contentWidth === 0 || divWidth === 0) {
        return
      }

      const maxFontSize = Number(div.dataset.maxFontSize)
      let fontSize = this.getFontSize(div)

      while (contentWidth <= divWidth && fontSize <= maxFontSize) {
          fontSize += 1;
          this.setFontSize(div, fontSize)
          contentWidth = div.firstChild.scrollWidth;
      }

      while (contentWidth > divWidth || fontSize > maxFontSize) {
          fontSize -= 1;
          this.setFontSize(div, fontSize)
          contentWidth = div.firstChild.scrollWidth;
      }
    })
  }

  getFontSize(element) {
    const fontSize = window.getComputedStyle(element).getPropertyValue('font-size');

    return Number(fontSize.substring(0, fontSize.length - 2));
  }

  setFontSize(element, pixels) {
    element.style.fontSize = pixels + 'px';
  }
}
