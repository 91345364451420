var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"scale"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}],staticClass:"inline-flex"},[_c('div',{staticClass:"mb-4 relative z-40 flex items-center px-8 pb-2 font-bold font-highlights uppercase rounded-full overflow-hidden whitespace-nowrap transition-[background-color] duration-300 ease-in-out",class:[
        'text-' + _vm.item.color(),
        _vm.item.titleBackground(),
        _vm.large ? 'h-[100px] min-w-[100px] text-6xl' : 'h-[74px] min-w-[74px] text-4xl-5',
        _vm.smsLabel ? 'pt-1' : 'pt-3',
      ]},[_c('transition',{attrs:{"name":"title"}},[(!_vm.titleTransition)?_c('div',[_c('div',{staticClass:"fade relative"},[(_vm.smsLabel)?_c('div',{staticClass:"text-q-gray opacity-80 absolute bottom-0 w-full text-lg",class:_vm.large ? 'text-2xl ml-[46px] -mb-4' : 'text-lg ml-[38px] -mb-3'},[_vm._v("1 euro per bericht")]):_vm._e(),(_vm.smsLabel)?_c('div',{staticClass:"-mt-2",class:_vm.large ? 'text-5xl' : 'text-4xl'},[_vm._v("SMS naar 4666")]):_c('div',[_vm._v(_vm._s(_vm.item.title))])])]):_vm._e()])],1),(_vm.item.content.subtitle)?_c('div',{staticClass:"mb-4 text-white bg-q-gray relative z-30 flex items-center pt-3 pb-2 font-bold font-highlights uppercase rounded-r-full overflow-hidden whitespace-nowrap transition-[background-color] duration-300 ease-in-out",class:[
        _vm.large ? 'h-[100px] -ml-[50px] text-6xl pl-3' : 'h-[74px] -ml-[37px] text-4xl-5',
      ]},[_c('transition',{attrs:{"name":"subtitle"}},[(!_vm.titleTransition)?_c('span',[_c('span',{staticClass:"fade"},[_c('span',{staticClass:"pl-14 pr-8"},[_vm._v(" "+_vm._s(_vm.item.content.subtitle)+" ")])])]):_vm._e()])],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }