"use strict";

// Copied from `vr-frontend`.

/* eslint-disable */ 

var SockJS = require("sockjs-client");
var __slice = Array.prototype.slice;

var Q = function () {
    function e() {
    }

    return e.connections = {}, e.connect = function (t, n, r) {
        var i, s;
        return n == null && (n = "https://socket.qmusic.be/api"), r == null && (r = {}), s = {
            debug: !1,
            catchErrors: !1,
            maxRetryTimeout: 10
        }, (i = this.connections[n]) || (i = this.connections[n] = new e.Connection(n, r)), new e.Station(i, t)
    }, e
}();
Q.Connection = function () {
    function e(e, t) {
        this.host = e, this.options = t, this.connect()
    }

    return e.prototype.subscriptions = [], e.prototype.messages = [], e.prototype.lastAttempt = 1, e.prototype.connect = function () {
        var e = this;
        e.heartbeatTimeoutDuration = 65000;
        e.heartbeatTimeoutTimer = null;
        e._setHeartbeatTimeout = function () {
            clearTimeout(e.heartbeatTimeoutTimer)

            e.heartbeatTimeoutTimer = setTimeout(function () {
                console.log("Closing connection")
                e.socket._close(2e3, "Heartbeat timed out")
            }, e.heartbeatTimeoutDuration)
        }
        return this.socket = new SockJS(this.host),
            this.socket.addEventListener("heartbeat", function () {
                e._setHeartbeatTimeout()
            }),
            this.socket.onopen = function () {
                e._setHeartbeatTimeout()
                return e.log("Connected"), e.lastAttempt = 1, e.flushMessages()
            }, this.socket.onmessage = function (t) {
                e._setHeartbeatTimeout()
                var n, r, i, s, o, u, a, f, l;
                e.log(">>>", t.data);
                try {
                    r = JSON.parse(t.data)
                } catch (c) {
                    e.log("Failed to parse server response", t.data);
                    return
                }
                switch (r.action) {
                    case "data":
                        s = JSON.parse(r.data), s._push_id = (a = r.key) != null ? a : null, f = r.ids, l = [];
                        for (o = 0, u = f.length; o < u; o++) {
                            i = f[o];
                            try {
                                n = e.subscriptions[i], l.push(n(s.data, s))
                            } catch (h) {
                                e.log(h);
                                if (!e.options.catchErrors) throw h;
                                l.push(void 0)
                            }
                        }
                        return l
                }
            }, this.socket.onclose = function (t) {
                var n, r;
                e.log("Disconnected: " + t.code, t);
                clearTimeout(e.heartbeatTimeoutTimer)
                if ((n = t.code) === 1e3 || n === 1001 || n === 1002 || n === 1003 || n === 1004 || n === 1005 || n === 1006 || (r = t.code) === 2e3) {
                    setTimeout(function () {
                        return e.connect()
                    }, e.lastAttempt * 1e3), e.lastAttempt *= 2;
                    if (e.lastAttempt > e.options.maxRetryTimeout) return e.lastAttempt = e.options.maxRetryTimeout
                }
            }
    }, e.prototype.flushMessages = function () {
        var e, t, n, r, i;
        if (this.isConnected()) {
            r = this.messages, i = [];
            for (t = 0, n = r.length; t < n; t++) e = r[t], i.push(this.send.apply(this, e));
            return i
        }
    }, e.prototype.isConnected = function () {
        return this.socket.readyState === 1
    }, e.prototype.send = function () {
        var e;
        return e = 1 <= arguments.length ? __slice.call(arguments, 0) : [], this.isConnected() ? (this.log.apply(this, ["<<<"].concat(__slice.call(e))), this.socket.send(e)) : this.messages.push(e)
    }, e.prototype.log = function () {
        var e;
        e = 1 <= arguments.length ? __slice.call(arguments, 0) : [];
        if (this.options.debug && typeof console != "undefined" && console !== null && console.log != null) return console.log.apply(console, e)
    }, e.prototype._register = function (e) {
        var t;
        return t = this.subscriptions.length, this.subscriptions.push(e), t
    }, e
}();
Q.Station = function () {
    function e(e, t) {
        this.conn = e, this.station = t
    }

    return e.prototype.subscribe = function (e) {
        return new Q.Subscription(this.conn, this.station, e)
    }, e
}()
Q.Subscription = function () {
    function e(e, t, n) {
        this.conn = e, this.station = t, this.entity = n, this.callbacks = []
    }

    return e.prototype.subscribe = function (e) {
        return this.conn.subscribe(e)
    }, e.prototype.listen = function (e, t) {
        return t == null && (t = {}), this.on(null, e, t), this
    }, e.prototype.on = function (e, t, n) {
        var r, i;
        return n == null && (n = {}), r = this.conn._register(t), i = {
            action: "join",
            id: r,
            sub: {
                station: this.station,
                entity: this.entity,
                action: e
            }
        }, n.backlog && (i.backlog = n.backlog), this.conn.send(JSON.stringify(i)), this
    }, e
}()

var sock;

function QWebsocket(stationId, debug=false) {
    sock = Q.connect(stationId, null, { debug });
    this.sock = sock // Make this accessible
}

QWebsocket.prototype.playSongHandler = function (handler) {

    sock.subscribe("plays").on('play', function (data) {
        handler(data);
    }, { backlog: 1 });
}

QWebsocket.prototype.programHandler = function (handler) {
    sock.subscribe("program").on('change', function (data) {
        handler(data);
    }, { backlog: 1 });
}

QWebsocket.prototype.likesHandler = function (handler) {
    sock.subscribe('ratings').listen(function (rating, channel) {
        if (channel
            && channel.action && channel.action == 'thumbs_up'
            && channel.data
            && channel.data.user
            && channel.data.user.name
            && channel.data.user.profile_image
            && channel.entity
            && channel.entity.ratings
            && channel.data.user.id
        ) {
            handler({ name: channel.data.user.name, image: channel.data.user.profile_image, selector_code: channel.entity.ratings, user_id: channel.data.user.id });
        }
    });
}

module.exports = QWebsocket;
