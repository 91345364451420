<template>
  <div id="app" class="bg-q-red w-screen h-screen flex items-center justify-center">
      <div
        class="text-white font-highlights uppercase font-bold leading-none"
      >
        <ClockCountdown :end="new Date(2020,11, 17, 10, 43)" @finished="countdownFinished()" />
      </div>
  </div>
</template>

<script>
import ClockCountdown from './components/ClockCountdown.vue'

export default {
  components: { ClockCountdown }
}
</script>