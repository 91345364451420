<template>
  <div id="app" class="hd-format bg-screen">
    <transition name="fade">
      <div
        v-if="visible && currentEndDate"
        class="text-white font-highlights uppercase font-bold leading-none relative"
      >
        <h1 class="absolute text-position text-center text-10xl leading-extra-tight">
          De volgende<br /> ontsnappoging
        </h1>
        <div class="absolute clock-position">
          <ScreenCountdown :end="currentEndDate" @finished="countdownFinished()" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from 'axios'
import config from './config'

import ScreenCountdown from './components/ScreenCountdown.vue'

export default {
  components: { ScreenCountdown },
  data() {
    return {
      visible: true,
      endDates: [],
      currentEndDate: undefined
    }
  },
  mounted() {
    this.retrieveCountdownDates()
    setInterval(this.retrieveCountdownDates, 10000)
  },
  methods: {
    retrieveCountdownDates() {
      return axios.get(`${config.BACKEND_API_URL}/screen/countdown-dates`)
        .then(response => {
          this.endDates = response.data.map(date => new Date(date))
          this.computeCurrentEndDate()
        })
        .catch(error => {
          console.log("New countdown dates could not be retrieved, retrying in 10 seconds.", error)
        })
    },
    countdownFinished() {
      this.computeCurrentEndDate()
    },
    /**
     * Finds the next end date.
     *
     * Assumes that the list of end dates is sorted, oldest first.
     */
    computeCurrentEndDate() {
      const now = new Date()

      const newCurrentEndDate = this.endDates.find(endDate => endDate > now)

      if (!newCurrentEndDate) {
        this.visible = false
        this.currentEndDate = undefined

        return
      }

      if (!this.currentEndDate || newCurrentEndDate.getTime() != this.currentEndDate.getTime()) {
        this.visible = false
        setTimeout(() => {
          this.currentEndDate = newCurrentEndDate
          this.visible = true
        }, 2000)
      }

    }
  }
}
</script>

<style scoped>
.bg-screen {
  background: url('./assets/screen_background.jpg');
}

.text-position {
  left: 1215px;
  top: 170px;
}

.clock-position {
  left: 115px;
  top: 700px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
