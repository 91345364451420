<!-- For Wanted -->

<template>
  <div class="h-full w-full flex items-center">
    <div class="font-highlights leading-none flex com:flex-col items-center w-full space-x-6 com:space-x-0 com:space-y-12 com:text-white whitespace-nowrap">
      <div class="text-7xl com:text-12xl uppercase font-bold">
        Domien is al
      </div>
      <div class="text-7xl-5 com:text-14xl uppercase font-bold">
        <div class="flex leading-none space-x-4 com:space-x-8 text-q-gray">
          <div class="bg-white p-4 px-8 com:px-12 rounded-[4rem] space-x-2">
            <span class="w-16 com:w-40 mr-1 inline-block">{{ twoDigits(hours) }}</span>
            <span class="text-4xl com:text-6xl">uur</span>
          </div>
          <div class="bg-white p-4 px-8 com:px-12 rounded-[4rem] space-x-2">
            <span class="w-16 com:w-40 mr-1 inline-block">{{ twoDigits(minutes) }}</span>
            <span class="text-4xl com:text-6xl">minuten</span>
          </div>
          <div class="bg-white p-4 px-8 com:px-12 rounded-[4rem] space-x-2">
            <span class="w-16 com:w-40 mr-1 inline-block">{{ twoDigits(seconds) }}</span>
            <span class="text-4xl com:text-6xl">seconden</span>
          </div>
        </div>
      </div>
      <div class="text-7xl com:text-12xl uppercase font-bold">
        Op de vlucht
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['content'],
  data() {
    return {
      now: Math.trunc(new Date().getTime() / 1000),
      diff: 0,
      interval: null
    }
  },
  computed: {
    date() {
      if (!this.content.starts_at) {
        return this.now
      }
      return Math.trunc(Date.parse(this.content.starts_at) / 1000)
    },
    seconds() {
      return Math.trunc(this.diff) % 60
    },
    minutes() {
      return Math.trunc(this.diff / 60) % 60
    },
    hours() {
      return Math.trunc(this.diff / 60 / 60)
    }
  },
  watch: {
    now() {
      this.computeDiff()
    },
    date() {
      this.computeDiff()
    }
  },
  methods: {
    computeDiff() {
      this.diff = Math.max(this.now - this.date, 0)
    },
    twoDigits(value) {
      if (value.toString().length <= 1) {
        return '0' + value.toString()
      }
      return value.toString()
    }
  },
  mounted() {
    this.interval = setInterval(() => {
      this.now = Math.trunc(new Date().getTime() / 1000)
    }, 1000)
  },
  destroyed() {
    clearInterval(this.interval)
  }
}
</script>
