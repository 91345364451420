import ScheduleEntry from "./ScheduleEntry"

class NoScheduleEntry extends ScheduleEntry {
  constructor() {
    super({
      title: undefined,
      type: undefined,
      content: {},
      duration: undefined,
    })
  }
}

export default new NoScheduleEntry()
