<template>
  <div class="h-full">
    <div class="com:bg-white com:px-16 com:py-8 com:rounded-[4rem]">
      <div
        class="text-3xl-5 leading-tighter com:leading-tight max-4-lines message font-medium com:text-5xl-5"
        v-html="message"
      />
    </div>
    <div class="hidden com:block ml-20 w-12 overflow-hidden">
      <div class="h-32 bg-white rotate-[36deg] transform origin-top-right"></div>
    </div>
  </div>
</template>

<script>
import twemoji from 'twemoji'

export default {
  props: ['content'],
  computed: {
    message() {
      if (!this.content.message && this.content.image) {
        return `<strong>${this.content.name} stuurt een foto:</strong>`
      }
      return twemoji.parse(this.$sanitize(this.content.message)) 
    }
  }
}
</script>

<style>
.emoji {
  @apply h-8 w-8 inline;
}
</style>