<template>
  <div class="h-full flex">
    <div v-if="imageUrl" class="h-48 w-48 min-w-[12rem] -m-8 com:m-0 com:h-128 com:w-128 com:pb-12 com:pr-12 mr-8">
      <img :src="fullImageUrl" class="h-full w-full" />
    </div>
    <div class="flex-1 flex items-center justify-between font-highlights uppercase leading-none">
      <div class="com:hidden" :class="!makeRoomForRequests ? 'w-[1322px]' : 'w-[822px]'">
        <div class="font-semibold flex resizable whitespace-nowrap" data-max-font-size="72">
          <span>{{ title }}</span>
        </div>
        <div class="flex resizable whitespace-nowrap" data-max-font-size="56">
          <span>{{ artist }}</span>
        </div>
      </div>
      <transition name="fade" @before-enter="makeRoomForRequests = true" @after-leave="makeRoomForRequests = false">
        <div v-if="requesters.length" class="ml-8 h-48 -mt-8 -mb-8 -mr-8 p-6 pr-12 bg-q-gray bg-opacity-90 text-white max-w-[524px] flex flex-col justify-center">
          <h2 class="text-3xl font-semibold mb-1">Aangevraagd door</h2>
          <p class="text-2xl max-4-lines leading-[1.1]">
            {{  requesters.join(' • ') }}
          </p>
        </div>
      </transition>
      <div class="hidden com:block w-full">
        <div class="font-semibold flex resizable whitespace-nowrap" data-max-font-size="160">
          <span>{{ title }}</span>
        </div>
        <div class="flex resizable whitespace-nowrap" data-max-font-size="120">
          <span>{{ artist }}</span>
        </div>
      </div>
    </div>
    <div class="hidden used-to-trigger-rerenders">{{ content }}</div>
  </div>
</template>

<script>
import QWebSocket from '../../vendor/q-websocket'
import Resizer from '@/Resizer'
import stationId from '../../stationId'

const IMAGE_PREFIX = 'https://static1.qmusic.medialaancdn.be/cover/w300'

const resizer = new Resizer()

export default {
  props: ['content'],
  data() {
    return {
      track: undefined,
      title: undefined,
      artist: undefined,
      imageUrl: undefined,
      selectorCode: undefined,
      requesters: [],
      makeRoomForRequests: false,
    }
  },
  mounted() {
    new QWebSocket(stationId).playSongHandler(track => {
      console.log(track)

      if (this.title != track.title || this.artist != track.artist.name) {
        console.log(`Track started playing: ${track.artist.name} - ${track.title}`)

        this.selectorCode = track.selector_code
        this.title = track.title
        this.artist = track.artist.name
        this.imageUrl = track.thumbnail ? track.thumbnail : track.artist.photo

        this.requesters = []
        // TEMPORARILY DISABLED
        // this.fetchRequesters()

        // Preload image
        new Image().src = this.imageUrl

        this.$emit('track', track)
      }
    })
  },
  updated() {
    resizer.resize()
  },
  computed: {
    fullImageUrl() {
      return IMAGE_PREFIX + this.imageUrl
    }
  },
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: transform .3s ease-in-out;
}
.fade-enter, .fade-leave-to {
  transform: translateX(500px);
}
</style>
