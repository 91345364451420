<template>
  <div class="h-full flex com:block justify-between items-center font-highlights uppercase leading-none com:space-y-28 com:text-white whitespace-nowrap">
    <div class="flex com:block items-center space-x-6 com:space-x-0 com:space-y-12 com:text-center">
      <div class="text-7xl com:text-12xl font-semibold mt-2">
        Aantal nummers goed:
      </div>
      <div class="space-x-4 flex com:justify-center">
        <div
          v-for="i in content.max_score"
          :key="'s-' + i"
          class="rounded-full w-24 h-24 com:w-48 com:h-48 border-8 com:border-[16px] p-1 com:p-2 border-white"
        >
          <div v-if="i <= content.score" class="rounded-full h-full w-full bg-white flex items-center justify-center">
            <CheckIcon class="h-16 w-16 com:h-32 com:w-32 text-q-blue" />
          </div>
          <div v-else>
            <XIcon class="h-full w-full text-white" />
          </div>
        </div>
      </div>
    </div>
    <div class="pr-2 com:text-center">
      <div class="bg-white rounded-2xl com:rounded-3xl inline-block font-semibold p-4 com:px-8 text-5xl com:text-7xl w-[423px] com:w-auto text-q-blue">
        Volgende ontsnappoging
        <div class="font-bold flex resizable whitespace-nowrap w-full text-q-gray justify-center" data-max-font-size="100">
          <span>{{ content.next_score_check }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Resizer from '@/Resizer'
import { XIcon, CheckIcon } from "@vue-hero-icons/outline"

const resizer = new Resizer()

export default {
  props: ['content'],
  components: { XIcon, CheckIcon },
  updated() {
    setTimeout(() => resizer.resize('trigger a'), 100)
  }
}
</script>

<style scoped>
.w-score {
  width: 800px;
}
</style>