import Vue from 'vue'
import VueSanitize from 'vue-sanitize'
import App from './App.vue'
import Screen from './Screen.vue'
import CountdownScreen from './CountdownScreen.vue'
import './assets/tailwind.css'

Vue.config.productionTip = false

Vue.use(VueSanitize)

const main = async () => {
  const routes = {
    '/': App,
    '/screen': Screen,
    '/countdown': CountdownScreen
  }

  new Vue({
    data: {
      currentRoute: window.location.pathname
    },
    computed: {
      ViewComponent () {
        return routes[this.currentRoute]
      }
    },
    render (h) { return h(this.ViewComponent) }
  }).$mount('#app')
}

main()
