import axios from 'axios'
import BackendActions from './BackendActions'
import config from './config'
import ScheduleEntry from './ScheduleEntry'

export default class BackendScheduler {
  constructor() {
    this.items = []
    this.fallbackItems = []

    this.skipNowPlaying = false
    BackendActions.instance().listenForNowPlayingEnableEvent(() => this.skipNowPlaying = false)
    BackendActions.instance().listenForNowPlayingDisableEvent(() => this.skipNowPlaying = true)
  }

  nextItem() {
    if (this.items.length === 0) {
      console.log("Unexpectedly, no items were found. Falling back to previous items.")

      this.items.push(...this.fallbackItems)
    }

    const newItem = this.items.shift()

    // Retrieve new schedule if almost empty
    if (this.items.length === 1) {
      this.updateItems()
    }

    if (this.skipNowPlaying && newItem.type === 'now-playing') {
      return this.nextItem()
    }

    return newItem
  }

  updateItems() {
    return axios.get(`${config.BACKEND_API_URL}/scheduler`)
      .then(response => {
        if (response.data.length === 0) {
          this.clear()
          return
        }

        const newItems = response.data.map(item => new ScheduleEntry(item))

        this.preloadImages(newItems)

        this.items.push(...newItems)
        this.fallbackItems = newItems
      })
      .catch(error => {
        console.log("New scheduled entries could not be retrieved, retrying in 5 seconds.", error)

        setTimeout(() => this.updateItems(), 5000)
      })
  }

  clear() {
    this.fallbackItems = []
    this.items = []
  }

  preloadImages(items) {
    items.forEach(item => {
      if (item.content.image_url) {
        new Image().src = item.content.image_url
      }
    })
  }
}
