<template>
  <transition name="scale">
    <div v-show="visible" class="inline-flex">
      <div 
        :class="[
          'text-' + item.color(),
          item.titleBackground(),
          large ? 'h-[100px] min-w-[100px] text-6xl' : 'h-[74px] min-w-[74px] text-4xl-5',
          smsLabel ? 'pt-1' : 'pt-3',
        ]"
        class="
          mb-4
          relative z-40
          flex items-center px-8 pb-2
          font-bold font-highlights uppercase
          rounded-full
          overflow-hidden whitespace-nowrap
          transition-[background-color] duration-300 ease-in-out"
      > 
        <transition name="title">
          <div v-if="!titleTransition">
            <div class="fade relative">
              <div v-if="smsLabel" class="text-q-gray opacity-80 absolute bottom-0 w-full text-lg" :class="large ? 'text-2xl ml-[46px] -mb-4' : 'text-lg ml-[38px] -mb-3'">1 euro per bericht</div>
              <div v-if="smsLabel" class="-mt-2" :class="large ? 'text-5xl' : 'text-4xl'">SMS naar 4666</div>
              <div v-else>{{ item.title }}</div>
            </div>
          </div>
        </transition>
      </div>
      <div 
        v-if="item.content.subtitle"
        :class="[
          large ? 'h-[100px] -ml-[50px] text-6xl pl-3' : 'h-[74px] -ml-[37px] text-4xl-5',
        ]"
        class="
          mb-4
          text-white bg-q-gray
          relative z-30
          flex items-center pt-3 pb-2
          font-bold font-highlights uppercase
          rounded-r-full
          overflow-hidden whitespace-nowrap
          transition-[background-color] duration-300 ease-in-out"
      >
        <transition name="subtitle">
          <span v-if="!titleTransition">
            <span class="fade">
              <span class="pl-14 pr-8">
                {{ item.content.subtitle }}
              </span>
            </span>
          </span>
        </transition>
      </div>
    </div>
  </transition>
</template>

<script>
// import stationId from '../stationId'

export default {
  props: ['large', 'item', 'titleTransition', 'visible'],
  computed: {
    smsLabel() {
      return false
      // return this.item.title == 'Q-App' && stationId == 'qmusic_be'
    }
  },
}
</script>

<style scoped>

.scale-leave-active,
.scale-enter-active {
  transition: all .1s ease-in-out;
  transform: scale(1);
}
.scale-enter, .scale-leave-to {
  transform: scale(0);
}

.title-leave-active,
.title-enter-active {
  transition: all .5s ease-in-out;
  max-width: 800px;
}
.title-enter, .title-leave-to {
  max-width: 0px;
}

.subtitle-leave-active {
  transition: all .4s ease-in-out;
  max-width: 800px;
}
.subtitle-enter-active {
  transition: all .7s ease-in-out;
  max-width: 800px;
}
.subtitle-enter, .subtitle-leave-to {
  max-width: 0px;
}

.title-leave-active .fade,
.title-enter-active .fade {
  transition: all .1s ease-in-out;
}

.title-leave-active .fade {
  transition-delay: .4s;
}

.title-enter .fade, .title-leave-to .fade {
  opacity: 0;
}
</style>
