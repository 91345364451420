<template>
  <div class="text-3xl-5 com:text-5xl-5 com:font-semibold leading-snug com:leading-tight h-full flex items-center">
    <div>
      <div class="max-4-lines com:pr-16">
        {{ content.message }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['content']
}
</script>
