<template>
  <div class="relative">
    <transition :name="transition ? 'fade' : ''">
      <div v-if="currentItem == 'image-a'">
        <img :src="imageA" class="absolute object-cover w-full aspect-video scale-[2.0] opacity-50" />
        <img :src="imageA" class="absolute z-20 object-contain w-full aspect-video" />
      </div>
    </transition>
    <transition :name="transition ? 'fade' : ''">
      <div v-if="currentItem == 'image-b'">
        <img :src="imageB" class="absolute object-cover w-full aspect-video scale-[2.0] opacity-50" />
        <img :src="imageB" class="absolute z-20 object-contain w-full aspect-video" />
      </div>
    </transition>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  props: ['bannerImage'],
  data() {
    return {
      transition: false,
      imageA: undefined,
      imageB: undefined,
      currentItem: 'banner',
    }
  },
  watch: {
    bannerImage(newValue, oldValue) {
      if (!this.bannerImage) {
        return;
      }
      this.transition = !!oldValue
      Vue.nextTick(() => {
        if (this.currentItem !== 'image-a') {
          this.imageA = this.bannerImage
          this.currentItem = 'image-a'
          return;
        }
        if (this.currentItem !== 'image-b') {
          this.imageB = this.bannerImage
          this.currentItem = 'image-b'
          return;
        }
      })
    },
  },
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
