const colors = {
  'now-playing': 'q-yellow',
  'app-message': 'q-turqoise',
  'headline': 'q-purple',
  'long-text': 'white',
  'score-update': 'q-blue',
  'count-up': 'q-red',
}

export default class ScheduleEntry {
  constructor(item) {
    this.title = item.title
    this.type = item.type
    this.content = item.content
    this.duration = item.duration
  }

  titleBackground() {
    return this.type === 'long-text' ? 'bg-q-red' : 'bg-white'
  }

  color() {
    return colors[this.type]
  }

  transitionColor() {
    return this.type === 'long-text' ? 'q-red' : this.color()
  }
}
