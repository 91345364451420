<template>
  <div>
    <div class="flex items-baseline text-28xl">
      <div class="text-center mr-4">{{ twoDigits(hours) }}</div>
      <div>:</div>
      <div class="text-center mx-4">{{ twoDigits(minutes) }}</div>
      <div>:</div>
      <div class="text-left w-80 ml-4">{{ twoDigits(seconds) }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['end'],
  data() {
    return {
      now: Math.trunc(new Date().getTime() / 1000),
      diff: 0,
      interval: null
    }
  },
  computed: {
    date() {
      return Math.trunc(this.end / 1000)
    },
    seconds() {
      return Math.trunc(this.diff) % 60
    },
    minutes() {
      return Math.trunc(this.diff / 60) % 60
    },
    hours() {
      return Math.trunc(this.diff / 60 / 60)
    }
  },
  watch: {
    now() {
      this.computeDiff()
    },
    date() {
      this.computeDiff()
    }
  },
  methods: {
    computeDiff() {
      this.diff = this.date - this.now
      if (this.diff < 0) {
        this.diff = 0
        this.$emit('finished')
      }
    },
    twoDigits(value) {
      if (value.toString().length <= 1) {
        return '0' + value.toString()
      }
      return value.toString()
    }
  },
  mounted() {
    this.computeDiff()

    this.interval = setInterval(() => {
      this.now = Math.trunc(new Date().getTime() / 1000)
    }, 1000)
  },
  destroyed() {
    clearInterval(this.interval)
  }
}
</script>