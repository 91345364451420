<template>
  <div id="app" :class="background" class="hd-format antialiased">
    <ProgramLine v-if="showProgramLine" />
    <Logo v-else class="absolute top-0 right-0 z-40 w-[75px] m-[92px]" />
    <Commercials :active-on-start="commercialsInitiallyActive()" />
    <DynamicGraphics />
  </div>
</template>

<script>
import Logo from './components/Logo.vue'
import DynamicGraphics from './components/DynamicGraphics.vue'
import Commercials from './components/Commercials.vue'
import ProgramLine from './components/ProgramLine.vue'
import stationId from './stationId'

export default {
  name: 'App',
  components: {
    Logo,
    ProgramLine,
    DynamicGraphics,
    Commercials,
  },
  methods: {
    isBackgroundRequested() {
      return new URL(location.href).searchParams.has('background')
    },
    commercialsInitiallyActive() {
      return new URL(location.href).searchParams.has('com')
    }
  },
  computed: {
    background() {
      const params = new URL(location.href).searchParams
      if (params.has('placeholder')) {
        return 'placeholder-background'
      }
      if (params.has('background')) {
        return 'bg-black'
      }
      return null
    },
    showProgramLine() {
      return stationId === 'qmusic_be'
    },
  },
}
</script>

<style>
.placeholder-background {
  @apply bg-cover;
  background-image: url('assets/placeholder.png');
}
</style>
