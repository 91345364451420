<template>
  <div v-show="active" class="w-full h-full absolute z-50 flex items-center justify-center">
    <div v-if="showBackground" class="absolute h-full w-full z-40">
      <video
        autoplay
        loop
        muted
        playsinline
        class="h-full w-full object-cover"
        src="/commercials_background_final.webm" />
    </div>
    <div class="absolute z-[100] left-0 bottom-0 mx-24 mb-16">
      <Label :large="true" :item="item" :visible="visible" :titleTransition="titleTransition" />
    </div>
    <transition name="slide">
      <div v-if="transition" class="absolute z-[60] h-full w-[250%]">
        <div :class="'bg-' + transitionColor" class="w-full h-full -skew-x-15" />
      </div>
    </transition>
    <div
      :class="[
        'bg-' + item.color(),
        centerContent && 'justify-center',
      ]"
      class="relative z-40 w-full h-full flex flex-col bg-opacity-70 overflow-hidden"
    >
      <div class="px-24 py-32">
        <transition name="fade">
          <div class="text-q-gray com" v-if="contentVisible">
            <NowPlaying v-show="item.type === 'now-playing'" :content="item.content" />
            <AppMessage v-show="item.type === 'app-message'" :content="item.content" />
            <Headline v-show="item.type === 'headline'" :content="item.content" />
            <LongText v-show="item.type === 'long-text'" :content="item.content"  />
            <ScoreUpdate v-show="item.type === 'score-update'" :content="item.content" />
            <CountUp v-show="item.type === 'count-up'" :content="item.content" />
          </div>
        </transition>
      </div>
    </div>
    <transition name="popup">
      <div v-show="visible && image" class="overflow-hidden absolute mr-24 mb-20 z-50 bottom-0 right-0">
        <div class="rounded-[4rem] bg-black overflow-hidden w-[900px] aspect-video">
          <DynamicGraphicsImage :banner-image="image" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import BackendActions from './../BackendActions'
import BackendScheduler from './../BackendScheduler'
import NoScheduleEntry from './../NoScheduleEntry'

import Label from './Label.vue'
import DynamicGraphicsImage from './DynamicGraphicsImage.vue'

import NowPlaying from './items/NowPlaying.vue'
import AppMessage from './items/AppMessage.vue'
import Headline from './items/Headline.vue'
import LongText from './items/LongText.vue'
import ScoreUpdate from './items/ScoreUpdate.vue'
import CountUp from './items/CountUp.vue'

const transitionTime = 800
const titleTransitionTime = 500

export default {
  components: { DynamicGraphicsImage, Label, NowPlaying, AppMessage, Headline, LongText, ScoreUpdate, CountUp },
  props: ['activeOnStart'],
  data() {
    return {
      item: NoScheduleEntry,
      image: null,
      active: false,
      becomingActive: false,
      visible: false,
      contentVisible: true,
      centerContent: true,
      transition: false,
      transitionColor: 'black',
      titleTransition: false,
      scheduler: new BackendScheduler(),
      timeout: null
    }
  },
  mounted() {
    if (this.activeOnStart) {
      this.activate()
    }
    BackendActions.instance().listenForCommercialsOnEvent(() => {
      setTimeout(() => { this.activate() }, 1000)
    })
    BackendActions.instance().listenForCommercialsOffEvent(this.deactivate)
  },
  methods: {
    setNextItem() {
      if (!this.active) {
        this.item = NoScheduleEntry
        return
      }

      const newItem = this.scheduler.nextItem()

      // If no item available, deactivate and hide
      if (newItem === undefined) {
        this.item = NoScheduleEntry
        return
      }

      this.showNewItem(newItem)

      // Set next item after this one has finished
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => this.setNextItem(), newItem.duration)
    },
    showNewItem(newItem) {
      this.titleTransition = true
      this.visible = true
      this.contentVisible = true

      const isNewType = this.item.type != newItem.type

      if (!newItem.content.image_url || isNewType) {
        this.image = null
      }

      // Start main animation after title is hidden
      if (isNewType) {
        setTimeout(() => {
          this.transitionColor = newItem.transitionColor()
          this.transition = true
        }, titleTransitionTime / 2)
      } else {
        setTimeout(() => {
          this.contentVisible = false
        }, titleTransitionTime / 2)
      }

      // Perform after animation is halfway
      const browserSlownessMargin = 50
      setTimeout(() => {
        this.item = newItem
        if (isNewType) {
          this.centerContent = !newItem.content.image_url
        }
      }, titleTransitionTime/2 + transitionTime / 3 + browserSlownessMargin)
      setTimeout(() => {
        this.contentVisible = true
        this.image = newItem.content.image_url
        if (!isNewType) {
          this.centerContent = !newItem.content.image_url
        }
      }, titleTransitionTime/2 + 2 * transitionTime / 3)

      // Reset animation
      setTimeout(() => { this.titleTransition = false }, titleTransitionTime/2 + transitionTime / 2)
      setTimeout(() => { this.transition = false }, titleTransitionTime/2 + transitionTime)
    },
    activate() {
      if (!this.becomingActive && !this.active) {
        this.becomingActive = true
        this.scheduler.updateItems().then(() => {
          this.active = true
          this.setNextItem()
        })
      }
    },
    deactivate() {
      this.scheduler.clear()

      this.titleTransition = true
      this.image = null

      this.becomingActive = false

      setTimeout(() => {
        this.transition = true
      }, titleTransitionTime/2)

      setTimeout(() => {
        this.item = NoScheduleEntry
        this.visible = false
      }, titleTransitionTime/2 + transitionTime /2)

      setTimeout(() => {
        this.transition = false
        this.active = false
      }, titleTransitionTime/2 + transitionTime)
    }
  },
  computed: {
    showImage() {
      return this.item.content && this.item.content.image_url
    },
    showBackground() {
      return this.item != NoScheduleEntry
    },
  }
}
</script>

<style scoped>
.placeholder-background {
  background: url('../assets/placeholder.png');
}

.slide-leave-active,
.slide-enter-active {
  transition: all .8s linear;
  transform: translate(100%, 0);
}
.slide-enter {
  transform: translate(-100%, 0);
}
.slide-leave-to {
}

.slide-down-leave-active,
.slide-down-enter-active {
  transition: all 0.5s linear;
  transform: translate(0, 0);
}
.slide-down-enter, .slide-down-leave-to {
  transform: translate(0, 200%);
}

.slide-down-fast-leave-active,
.slide-down-fast-enter-active {
  transition: all 0.3s linear;
  transform: translate(0, 0);
}
.slide-down-fast-enter, .slide-down-fast-leave-to {
  transform: translate(0, 200%);
}

.slide-right-leave-active,
.slide-right-enter-active {
  transition: all 0.5s linear;
  transform: translate(0, 0);
}
.slide-right-enter {
  transform: translate(-100%, 0);
}
.slide-right-leave-to {
  transform: translate(100%, 0);
}

.popup-leave-active,
.popup-enter-active {
  transition: all .5s ease-in-out;
  max-width: 1920px;
}
.popup-enter, .popup-leave-to {
  max-width: 0;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s ease-in-out;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
