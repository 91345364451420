import Echo from 'laravel-echo'
import config from './config'
// eslint-disable-next-line no-unused-vars
import Pusher from 'pusher-js'

export default class BackendActions {
  static instance() {
      if (!BackendActions.singleInstance) {
        BackendActions.singleInstance = new BackendActions();
      }
      return BackendActions.singleInstance;
  }

  constructor() {
    const echo = new Echo({
      broadcaster: 'pusher',
      key: config.BACKEND_SOCKET_KEY,
      wsHost: config.BACKEND_SOCKET_HOST,
      wsPort: config.BACKEND_SOCKET_PORT,
      wssPort: config.BACKEND_SOCKET_PORT,
      forceTLS: true,
      disableStats: true,
    })

    this.channel = echo.channel('actions')
  }

  listenForInteractiveGraphicsShow(handler) {
    this.channel.listen('InteractiveGraphicsBarShowEvent', handler)

    return this
  }

  listenForInteractiveGraphicsHide(handler) {
    this.channel.listen('InteractiveGraphicsBarHideEvent', handler)

    return this
  }

  listenForCommercialsOnEvent(handler) {
    this.channel.listen('CommercialBreakShowEvent', handler)

    return this
  }

  listenForCommercialsOffEvent(handler) {
    this.channel.listen('CommercialBreakHideEvent', handler)

    return this
  }

  listenForNowPlayingEnableEvent(handler) {
    this.channel.listen('NowPlayingEnableEvent', handler)

    return this
  }

  listenForNowPlayingDisableEvent(handler) {
    this.channel.listen('NowPlayingDisableEvent', handler)

    return this
  }
}
